<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="520"
    >
        <v-card
            class=""
            flat
            width="520"
        >
            <v-system-bar  window color="primary" dark>
                <v-spacer></v-spacer>
                <v-btn small icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-system-bar>

            <v-toolbar color="primary" dark lights-out>
                <v-toolbar-title> User Employee Permissions </v-toolbar-title>
            </v-toolbar>

            <v-card-title class="mb-4">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search Username"
                    single-line
                    hide-details
                    clearable
                ></v-text-field>
            </v-card-title>

            <v-data-table
                :headers="headers"
                :items="userPermissions"
                :items-per-page="2"
                fixed-header
                :search="search"
                height="400"
            >
                 <template v-slot:item="props">
                    <tr>
                        <td>{{ props.item.user_name }}</td>
                        <td> 
                            <v-select
                                v-model="props.item.user_permissions"
                                :items="permissions"
                                item-text="detail"
                                item-value="id"
                                chips
                                deletable-chips
                                small-chips
                                multiple
                                @change="updatePermissions(props.item)"
                            ></v-select>
                        </td>
                    </tr>
                 </template>
            </v-data-table>

            <v-overlay
                :value="overlay"
                opacity="0.8"
                z-index="10"
                absolute                    
            >
                
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>                   
            </v-overlay>

            <v-card-actions>
                <SaveStatusIndicator
                    :saveStatus="saveStatus"
                />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template> 

<script>
import { mapActions, mapMutations } from 'vuex';
import SaveStatusIndicator from './SaveStatusIndicator.vue';
export default {
    props: {
        dialogOpen: Boolean,
    },

    components: {
        SaveStatusIndicator,
    },

    created () {
        this.initialize();
    },

    data: function () {
        return {
            dialog: this.dialogOpen,
            overlay: false,
            headers: [
                {text: 'User', value: 'user_name', sortable: false},
                {text: 'User Permissions', value: 'user_permissions', sortable: false}
            ],
            userPermissions: [],
            permissions: [],
            search: null,
            saveStatus: {
                saving: false,
                save: false,
                error: false
            }
        }
        
    },

    methods: {
        ...mapActions({
            getUserPermissionAssignments: 'settings/getUserPermissionAssignments',
            postUserPermissionAssignments: 'settings/postUserPermissionAssignments',
        }),

        ...mapMutations({
            setUserId: 'settings/setUserId',
            setUserPermissionAssignments: 'settings/setUserPermissionAssignments',
        }),

        async initialize () {
            this.overlay = true;
            try {
                const { data: { users, user_permissions} } = await this.getUserPermissionAssignments();
                this.mapUserPermissions(users);
                this.permissions = user_permissions;
            } catch (error) {
                    if(error.response) console.log(error.response)
                    else console.log(error);
            }
           this.overlay = false;
        },

        mapUserPermissions (data) {
            data.forEach( val => {
                this.userPermissions.push({
                    user_id: val.user_id,
                    user_name: val.user_name,
                    user_permissions: val.user_permissions
                })
            })

        },

        async updatePermissions ({ user_id, user_permissions}) {
            this.saveStatus.save = false;
            this.saveStatus.error = false 
            this.saveStatus.saving = true;
            
            this.setUserId(user_id);    
            this.setUserPermissionAssignments(user_permissions);
            try {
                await this.postUserPermissionAssignments();
                this.saveStatus.saving = false;

                // this.clearSaveStatus()
                setInterval(() => {
                    this.saveStatus.save = true;
                }, 600)
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error)
                this.saveStatus.saving = false;
                // this.clearSaveStatus()
                setInterval(() => {
                    this.saveStatus.error = true;
                }, 600)
            }
        },

        clearSaveStatus () {
            Object.keys(this.saveStatus).forEach(key => {
                this.saveStatus[key] = false;
            })
            console.log(this.saveStatus)
        },

        close () {
            this.$emit('dialog-close');
        }

    }
}
</script>

<style scoped>
.v-sheet.v-toolbar:not(.v-sheet--outlined){
    box-shadow: none;
}
</style>