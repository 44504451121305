<template>
    <v-card
        flat
    >
        <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search for teacher"
                single-line
                hide-details
                clearable
            ></v-text-field>
        </v-card-title>

        <v-data-table
            class="mt-10"
            :headers="headers"
            :items="employees"
            :search="search"
            fixed-header
            height="25vh"
            :loading="loading"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                    text
                    small
                    outlined
                    color="primary"
                    class="mr-4"
                    @click="editEmployee(item)"
                >
                    <v-icon
                        small
                        class="mr-2"                                       
                    >
                        mdi-pencil
                    </v-icon>
                    Edit
                </v-btn>  
                <v-btn
                    text
                    small
                    outlined
                    color="primary"
                    class="mr-4"
                    @click="deleteEmployee(item)"
                >
                    <v-icon
                        small
                        class="mr-2"                                       
                    >
                        mdi-delete
                    </v-icon>
                    Delete
                </v-btn>                               
            </template>
        </v-data-table>

        <v-dialog
            v-model="dialogEdit"
            max-width="450px"
            persistent
        >
            <v-card
                class="pa-6"
                v-if="dialogEdit"
                ref="form"
            >
                <v-card-title
                    class="pl-0"
                >
                    Edit Teacher
                </v-card-title>

                <v-alert
                    :value="alert.display"
                    dense
                    text
                    :type="alert.type"
                    transition="scale-transition"
                >
                    {{ alert.text }}
                </v-alert>

                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="editedEmployee.first_name"
                            label="First Name"
                            :rules="[rules.required]"
                            ref="first_name"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="editedEmployee.last_name"
                            label="Last Name"
                            :rules="[rules.required]"
                            ref="last_name"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="editedEmployee.email"
                            label="Email"
                            :rules="[rules.email]"
                            ref="email"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="editedEmployee.user_name"
                            label="User Name"
                            :rules="[rules.required]"
                            ref="user_name"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary" 
                        @click="updateTeacher"                       
                    >
                        Update Teacher
                    </v-btn>
                    <v-btn
                        text
                        outlined
                        color="primary"
                        @click="closeEditTeacher"
                    >
                        Close
                    </v-btn>
                </v-card-actions>

                <v-overlay :value="overlayEdit" absolute>
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </v-overlay>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogDelete"
            max-width="600px"
            persistent
        >
            <v-card
                v-if="confirmDelete"
            >
                <v-card-title>{{ teacherDeleted }}</v-card-title>
                <v-card-text>                                        
                    <v-select
                        :items="statuses"
                        v-model="status"
                        label="Status"                                            
                        item-text="status"
                        item-value="id"
                        autofocus
                        hide-details
                        ref="student-status"
                        clearable
                        open-on-clear
                    ></v-select>
                </v-card-text>
                <v-card-actions
                    class="px-6"
                >
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="closeDelete"
                        outlined
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="deleteConfirm"
                        outlined
                    >
                        Delete
                    </v-btn>                                        
                </v-card-actions>
            </v-card>
            <v-expand-transition>
                <v-card
                    v-show="deleting"
                    color="primary"
                    dark
                    class="pa-4"
                >
                    <v-card-text>
                        <span class="mr-2">Deleting</span> {{ teacherDeleted }}
                    <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0 mt-4"
                    ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-expand-transition>
        </v-dialog> 
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    data: () => ({
        search: '',
        loading: false,
        editedEmployee: {},
        deletedEmployee: {},
        headers: [
            {text: 'ID', align: 'start', sortable: 'false', value: 'id', width: '100'},
            {text: 'Last Name', value: 'last_name', width: '120'},
            {text: 'First Name', value: 'first_name', width: '120'},
            {text: 'User Name', value: 'user_name', width: '120'},
            {text: '', value: 'actions', sortable: 'false', }
        ],
        dialogEdit: false,
        dialogDelete: false,
        confirmDelete: false,
        deleting: false,        
        status: 2,
        alert: {
            type: null,
            text: null,
            display: false,
        },
        alertDefault: {
            type: null,
            text: null,
            display: false,
        },
        overlayEdit: false,
        rules: {
            required: value => !!value || 'Required.',
            email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
            }
        },
        editFormHasErrors: false,
    }),
    watch: {
        employees: {
            handler(val){
                if(val.length != 0) this.loading = false;
            }
        }
    },
    computed: {
        ...mapGetters({
            employees: 'employees/getEmployees',
            statuses: 'employees/getEmployeeStatuses',
        }),
        teacherDeleted () {
            return this.deletedEmployee.first_name + ' ' + this.deletedEmployee.last_name
        }
        
    },
    methods: {
        ...mapActions({
            postEmployee: 'employees/postEmployee',            
            delete: 'employees/deleteEmployee',
            getEmployees: 'employees/getEmployees',            
        }),
        ...mapMutations({
            setEditedEmployee: 'employees/setEditedEmployee',
            setDeletedEmployee: 'employees/setDeletedEmployee',
            setEmployees: 'employees/setEmployees',
            setSnack: 'employees/setSnack',
            setSnackText: 'employees/setSnackText',
        }),

        editEmployee(item){
            this.editedEmployee = Object.assign({}, item);
            this.dialogEdit = true;
        },
        deleteEmployee(item){
             this.deletedEmployee = item;             
             this.dialogDelete = true;
             this.confirmDelete = true;
        },
        async deleteConfirm(){
            console.log('delete confirmed');
            this.confirmDelete = false;
            this.deleting = true;            
            this.setDeletedEmployee({
                id: this.deletedEmployee.id,
                employee_status_id: this.status
            })
            try {
                let response = await this.delete();
                this.deleting = false;
                this.dialogDelete = false;
                response = await this.getEmployees();
                this.setEmployees(response.data);                                
                //this.employees = response.data;  
                //console.log(response);
               this.setSnack(true);
               this.setSnackText(this.deletedEmployee.first_name + ' ' + this.deletedEmployee.last_name + ' deleted '); 
            } catch (error) {
                console.log(error);
                this.setSnack(true);
                this.setSnackText('An Error Occured');                
            }
        },
        closeDelete(){
            this.dialogDelete = false;
            this.status = 2;
        },
        async updateTeacher(){
            this.setEditedEmployee(this.editedEmployee);
            if(
                !this.editedEmployee.first_name ||
                !this.editedEmployee.last_name ||
                !this.editedEmployee.user_name

            ){
                console.log(Object.keys(this.editedEmployee));
                Object.keys(this.editedEmployee).forEach(key => {
                    if(this.$refs[key]){
                        this.$refs[key].validate(true);
                    }
                })
                this.alert = {
                    display: true,
                    type: 'error',
                    text: 'Please fill in missing fields.'
                }
                // this.$refs.form.validate();
                return;
            }

            this.overlayEdit = true;
            this.alert = {...this.alertDefault}
            try {
                await this.postEmployee();
                
                const { data } = await this.getEmployees();
                this.setEmployees(data);                                
                
                this.alert = {
                    display: true,
                    type: 'success',
                    text: 'Record saved successfully.'
                }

            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
                this.alert = {
                    display: true,
                    type: 'error',
                    text: 'Record not saved. An error occured.'
                }   
            }
            this.overlayEdit = false;
        },
        closeEditTeacher(){
            this.dialogEdit = false;
            this.alert = {...this.alertDefault}
        },
        
    },
    
}
</script>