import axios from 'axios'
export default{
    namespaced: true,
    state: {
        selectedItem: { item: -1},
        deletedStudent: '',
        dialogSetTerm: false,
        currentTerm: null,
        termRegistrationData: null,
        backdateAcademicTermId: null,
        dialogAddEditSubjects: false,
        editedSubject: {},  
        editedTerm: {},  
        userPermissionAssignments: [],    
        userId: null,     
    },

    getters: {
        getSelectedItem (state) {
            return state.selectedItem
        },
        
        getDeletedStudent(state){
            return state.deletedStudent
        },

        getDialogSetTerm (state) {
            return state.dialogSetTerm
        },

        getCurrentTerm (state) {
            return state.currentTerm
        },

        getTermRegistrationData (state) {
            return state.termRegistrationData
        },

        getBackdateAcademicTermId (state) {
            return state.backdateAcademicTermId
        },

        getDialogAddEditSubjects (state) {
            return state.dialogAddEditSubjects
        },

        getEditedSubject (state) {
            return state.editedSubject
        },

        getEditedTerm (state) {
            return state.editedTerm
        },

        getUserPermissionAssignments (state) {
            return state.userPermissionAssignments
        },
    
        getUserId (state) {
            return state.userId
        },
    },

    mutations: {
        setSelectedItem (state, value) {
            state.selectedItem = value
        },

        setDeletedStudent(state, value){
            state.deletedStudent = value
        },

        setDialogSetTerm (state, value) {
            state.dialogSetTerm = value
        },

        setCurrentTerm (state, value) {
            state.currentTerm = value
        },

        setTermRegistrationData (state, value) {
            state.termRegistrationData = value
        },

        setBackdateAcademicTermId (state, value) {
            state.backdateAcademicTermId = value
        },

        setDialogAddEditSubjects (state, value) {
            state.dialogAddEditSubjects = value
        },

        setEditedSubject (state, value) {
            state.editedSubject = value
        },

        setEditedTerm (state, value) {
            state.editedTerm = value
        },

        setUserId (state, value) {
            state.userId = value
        },

        setUserPermissionAssignments (state, value) {
            state.userPermissionAssignments = value
        },
    },

    actions: {
        getTermRegistrations(){
            let url = '/api/term-registration';
            return axios.get(url);
        },

        deleteTermRegistration({ getters }){
            let url = '/api/term-registration';
            return axios.delete(url, {
                data: getters.getDeletedStudent
            })
        },

        getCurrentTerm () {
            let url = '/api/current-term';
            return axios.get(url);
        },

        getNextTerm () {
            let url = '/api/next-term';
            return axios.get(url);
        },

        postCurrentTerm ({ getters }) {
            let url = '/api/current-term';
            //console.log(getters.getCurrentTerm);
            return axios.post(url, getters.getCurrentTerm);
        },

        postTermRegistration ({ getters }) {
            let url = '/api/term-registration';
            // console.log(getters.getTermRegistrationData);
            return axios.post(url, getters.getTermRegistrationData);
        },

        getAvailableTerms () {
            const url = '/api/term-history';
            return axios.get(url);
        },

        backdateTerm ({ getters }) {
            const url = '/api/backdate-term';
            // console.log(getters.getBackdateAcademicTermId);
            return axios.post(url, {academic_term_id: getters.getBackdateAcademicTermId});
        },

        getSubjects () {
            const url = '/api/subjects';
            return axios.get(url);
        },

        postSubject ({ getters }) {
            const url = '/api/subjects';
            return axios.post(url, getters.getEditedSubject);
        },

        deleteSubject ({ getters }) {
            const url = '/api/subject';            
            return axios.delete(url, { data: { id: getters.getEditedSubject.id }})
        },

        promoteStudents () {
            const url = '/api/promote';
            return axios.post(url);
        },

        getTermReports () {
            const url = '/api/term-reports';
            return axios.get(url);
        },

        postTermReports({ getters }){
            let url = 'api/post-term-reports';
            return axios.post(url, getters.getEditedTerm);
        },

        getUserPermissionAssignments () {
            const url = '/api/user-permission-assignments';
            return axios.get(url);
        },

        postUserPermissionAssignments ({ getters }) {
            const url = '/api/user-permission-assignments';
            return axios.post(url, {
                permissions: getters.getUserPermissionAssignments,
                userId: getters.getUserId
            })
        },
    }
}