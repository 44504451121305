import  axios  from "axios"

export default {
    namespaced: true,
    state: {
        markSheetOverlay: false,
        markSheetLoading: false,
        expandLessons: false,
        displayLessons: false,
        displayTeachers: false,
        totalStudents: null,
        teacherLessons: [],
        lessonSelected: {
            lesson: '',
            className: '',
            subjectCode: '',
            subjectTitle: '',
            formLevel: ''
        },
        termMarkRecords: [],
        displayPresetComment: false,
        updatedTermMarkRecord:{},
        studentSelected: '',
        selectedRecordIndex: -1,
        presetComments: [],
        presetComment: '',
        selectedTeacher: '',
        expandTeachers: false, 
        lessonClasses: [],
        lessonClassesWithStudents: [],
    },    
    getters: {
        getDisplayLessons (state) {
            return state.displayLessons
        },

        getDisplayTeachers (state) {
            return state.displayTeachers
        },

        getMarkSheetOverlay(state){
            return state.markSheetOverlay
        },
        getMarkSheetLoading(state){
            return state.markSheetLoading
        },
        getExpandLessons(state){
            return state.expandLessons
        },        
        getTeacherLessons(state){
            return state.teacherLessons
        },
        getLessonSelected(state){
            return state.lessonSelected
        },
        getTermMarkRecords(state){
            return state.termMarkRecords
        },
        getTotalStudents(state){
            return state.totalStudents
        },
        getDisplayPresetComment(state){
            return state.displayPresetComment
        },
        getUpdatedTermMarkRecord(state){
            return state.updatedTermMarkRecord
        },
        getStudentSelected(state){
            return state.studentSelected
        },
        getSelectedRecordIndex(state){
            return state.selectedRecordIndex
        },
        getPresetComments(state){
            return state.presetComments
        },        
        getPresetComment(state){
            return state.presetComment
        },

        getSelectedTeacher (state) {
            return state.selectedTeacher
        },

        getExpandTeachers (state) {
            return state.expandTeachers
        },

        getLessonClasses(state){
            return state.lessonClasses
        },

        getLessonClassesWithStudents(state){
            return state.lessonClassesWithStudents
        },
    },
    mutations: {
        setDisplayLessons (state, value) {
            state.displayLessons = value
        },

        setDisplayTeachers (state, value) {
            state.displayTeachers = value
        },
        
        setMarkSheetOverlay(state, value){
            state.markSheetOverlay = value
        },
        setMarkSheetLoading(state, value){
            state.markSheetLoading = value
        },
        setExpandLessons(state, value){
            state.expandLessons = value
        },
        setTeacherLessons(state, value){
            state.teacherLessons = value
        },        
        setLessonSelected(state, value){
            state.lessonSelected = value
        },
        setTermMarkRecords(state, value){
            state.termMarkRecords = value
        },
        setTotalStudents(state, value){
            state.totalStudents = value
        },
        setDisplayPresetComment(state, value){
            state.displayPresetComment = value
        },
        setUpdatedTermMarkRecord(state, value){
            state.updatedTermMarkRecord = value
        },
        setStudentSelected(state, value){
            state.studentSelected = value
        },
        setSelectedRecordIndex(state, value){
            state.selectedRecordIndex = value
        },
        setPresetComments(state, value){
            state.presetComments = value
        },       
        setPresetComment(state, value){
            state.presetComment = value
        },

        setSelectedTeacher (state, value) {
            state.selectedTeacher = value
        },

        setExpandTeachers (state, value) {
            state.expandTeachers = value
        },

        setLessonClasses(state, value){
            state.lessonClasses = value
        },

        setLessonClassesWithStudents(state, value){
            state.lessonClassesWithStudents = value
        }
        
    },
    actions: {
        getLessons(context, id){
            let url = '/api/mark-sheet-teacher-lessons?employee_id=' + id;
            return axios.get(url);
        },

        getStudents({getters},{ academicTermId, employeeId}){
            const url = '/api/teacher-lesson-students';
            return axios.get(url, { params: {
                formClasses: getters.getLessonSelected.formClasses,
                academicTermId: academicTermId,
                subjectId: getters.getLessonSelected.subjectCode,
                employeeId: employeeId
            }});
        },
        
        getPresetCommentsList(){
            let url = '/api/preset-comments';
            return axios.get(url);
        },

        save ({getters}) {
            let url = '/api/term-marks';
            return axios.post(url, getters.getUpdatedTermMarkRecord);
        }
    }
}